import * as React from 'react';

import {Article} from '..';
import ArticleCard from '../ArticleCard';
import './ArticleList.scss';

interface Props {
  articles: Article[];
}

const ArticleList = ({articles}: Props) => {
  return (
    <div className={'article_list'}>
      <div className={'article_list__container'}>
        <h2 className={'article_list__title'}>Lately I've been..</h2>
      </div>

      {articles?.map((article: Article) => (
        <ArticleCard {...article} key={article.title} />
      ))}
    </div>
  );
};

export default ArticleList;
