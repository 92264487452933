import * as React from 'react';

import './InfoSection.scss';

const InfoSection = () => (
  <div className="info_page">
    {/*  Title & text */}
    <div>
      <div className="info_page__title">
        <p className="info_page__title__text">Kia ora, I'm Farrelly</p>
      </div>
      <div className="info_page__text">
        <p className={'text'}>
          Developer since way back, I've made websites and apps using React for years.
        </p>
        <p className={'text'}>
          If you're looking to hire, or contact me, message me on{' '}
          <a href={'https://www.linkedin.com/in/nick-farrelly-a85566159/'} className={'link url_link'}>
            Linkedin
          </a>
        </p>
      </div>
    </div>
  </div>
);
export default InfoSection;
