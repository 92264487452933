import {graphql} from 'gatsby';
import * as React from 'react';

import ArticleList from '../components/Lists/ArticleList';
import Layout from '../components/layout';
import InfoSection from '../components/pages/InfoSection';
import LandingPage from '../components/pages/LandingPage';
import Seo from '../components/seo';
import useRightClickStopper from '../hooks/useRightClickStopper';

const BlogIndex = ({data}) => {
  const posts = data.allMarkdownRemark.nodes;
  const articles = posts.map((post) => ({
    ...post.frontmatter,
    image: post.frontmatter.image.publicURL,
    url: post.fields.slug,
  }));
  useRightClickStopper();

  return (
    <Layout>
      <Seo title="All posts" />
      <LandingPage />
      <InfoSection />
      <ArticleList articles={articles} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`;
