// TODO change imports of React to be 'import React from 'react';
import {navigate} from 'gatsby';
import * as React from 'react';

import './ArticleCard.scss';
import {Article} from './index';

const ArticleCard = ({title, image, imageAlt, description, url}: Article) => {
  const onArticleClick = () => navigate(url);

  const Title = () => (
    <div className={'title'}>
      <h2 className={'title__text'} onClick={onArticleClick} role={'button'} tabIndex={0}>
        {title}
      </h2>
    </div>
  );
  const Body = () => (
    <div className={'body'}>
      <div className={'body__desc'}>
        <p className={'body__desc__text'}>{description}</p>
        <p className={'body__desc__read_more'}>
          {/* TODO investigate link not always working on first press */}
          <a href={url} className={'link'}>
            read article
          </a>
        </p>
      </div>
    </div>
  );
  const Image = () => (
    <div className={'image'}>
      {/* @ts-ignore TODO fix typing from string */}
      <img
        src={image}
        alt={imageAlt}
        className={'image__element'}
        onClick={onArticleClick}
        tabIndex={0}
        role={'button'}
        aria-label={'article image'}
      />
    </div>
  );
  return (
    // Repeated structure as desktop/mobile view need different HTML to retain the correct styling
    <article className={'article_card'}>
      <div className={'article_card--mobile'}>
        <Title />
        <Body />
        <Image />
      </div>

      <div className={'article_card--desktop'}>
        <Title />
        <div className={'wrapper'}>
          <Body />
          <Image />
        </div>
      </div>
    </article>
  );
};

export default ArticleCard;
