import * as React from 'react';

import useWindowSize from '../../hooks/useWindowSize';
// images
import COLUMN_IMG from '../../images/column.webp';
import FARRELLY_FIRE from '../../images/farrelly.gif';
import MACINTOSH from '../../images/macintosh.webp';
import './LandingPage.scss';

const Column = () => (
  <div className="landing__column">
    <img
      src={FARRELLY_FIRE}
      className="landing__column__farrelly"
      alt="The Farrelly text on fire"
    />
    <img
      src={COLUMN_IMG}
      className="landing__column__img"
      alt="Roman style column in old computer graphics style"
    />
  </div>
);

const LandingPage = () => (
  <div className="landing__container">
    <Column />

    {/*  Statue head */}
    <div className="landing__statue">
      <img
        src={FARRELLY_FIRE}
        className="landing__statue__farrelly"
        alt="The Farrelly in text on fire"
      />
      <img src={MACINTOSH} className="landing__statue__img" alt="Roman statue of a head" />
    </div>

    <Column />
  </div>
);

export default LandingPage;
