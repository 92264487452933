import {useEffect} from 'react';

const useRightClickStopper = () => {
  const onRightClick = (event: Event) => {
    event.preventDefault();
    alert("Right click disabled! ~~pls don't steal my code :'(");
  };

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => onRightClick(e));
    return document.removeEventListener('contextmenu', (e) => onRightClick(e));
  }, []);
};

export default useRightClickStopper;
